import React from "react";

export default function ImgContent({ item }) {
  return (
    <section id={item.id} className={`mb-0 img-content ${item.background}`}>
      <div className='container'>
        <div>
          <header
            className='section-header aos-init aos-animate'
            data-aos='fade-up'
            data-aos-offset='100'
          >
            <h2>{item.header}</h2>
            <div className='bar'></div>
          </header>
        </div>
        <div className='desc'>
          <p>{item.Paragraph}</p>
        </div>
        <div className='row'>
          <div
            className={`col-md-6 img-content aos-init aos-animate ${item.mt_div}`}
            data-aos='fade-right'
            data-aos-offset='100'
          >
            <img
              src={item.image}
              alt={item.alt_tag}
              className={`fixed-imgs img-fluid ${item.mt_img}`}
            />
            <img
              src='/imgs/animation/circle-shape.png'
              className='rotate bg-image rotateme'
              alt='TODAYFILINGS'
            />
          </div>
          <div
            className='col-md-6 mt-3 aos-init aos-animate'
            data-aos='fade-left'
            data-aos-offset='100'
          >
            {item.points.map((point, index) => (
              <div className='desc mt-4' key={index}>
                <h5>
                  <strong>{point.head}</strong>
                </h5>
                <div className='row'>
                  <div className='col-md-1'>
                    {point.icon ? (
                      <i className='bi bi-check-circle-fill'></i>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='col-md-11'>{point.content}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
