import React from "react";

export default function ContentImg({ CISection_data }) {
  return (
    <section
      id={CISection_data.id}
      className={`mt-5 ${CISection_data.background}`}
    >
      <div className='container mt-5'>
        <div>
          <header
            className='section-header aos-init aos-animate'
            data-aos='fade-up'
            data-aos-offset='100'
          >
            <h2>{CISection_data.header}</h2>
            <div className='bar'></div>
          </header>
          <p>{CISection_data.paragraph}</p>
        </div>
        <div className='row'>
          <div
            className='col-md-6 mt-3 aos-init aos-animate'
            data-aos='fade-right'
            data-aos-offset='100'
          >
            {CISection_data.points.map((point, index) => (
              <div className='desc mt-4' key={index}>
                <h5>
                  <strong>{point.head}</strong>
                </h5>
                <div className='row content-img'>
                  <div className='col-md-1'>
                    {point.icon ? (
                      <i className='bi bi-check-circle-fill'></i>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='col-md-11'>{point.content}</div>
                </div>
              </div>
            ))}
          </div>
          <div
            className={`col-md-6 content-imgs aos-init aos-animate ${CISection_data.mt_div}`}
            data-aos='fade-left'
            data-aos-offset='100'
          >
            <img
              src={CISection_data.image}
              alt={CISection_data.alt_tag}
              className={`fixed-imgs img-fluid ${CISection_data.mt_img}`}
            />
            <img
              src='/imgs/animation/circle-shape.png'
              className='bg-image rotateme mt-5'
              alt='TODAYFILINGS'
            />
          </div>
        </div>
      </div>
    </section>
  );
}
